.form {
  margin-top: 1rem;
  /* flex-direction: row; */
  /* float: left; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.title {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}

.form-control {
  margin-bottom: 1rem;
}

.form button {
  background-color: #e6072c;
  color: #fff;
  border-radius: 4px;
  width: 30%;

  padding: 0.6rem 0.8rem;
  margin-top: 1rem;
}

.button-lg {
  margin-left: 1rem;
  flex-basis: 250px;
}
