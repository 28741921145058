.paper {
    padding: 1rem;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 7px;
    color: #525252;
}

.button-solid {
    background-color: #e6072c;
    color: #fff;
}

.button-mutted {
    background-color: #f5e9eb;
    color: #e6072c;
    margin-left: 0.5rem;
}

.button-solid,
.button-mutted {
    padding: 0.57rem 2rem;
    border-radius: 5px;
    white-space: nowrap;
}

.row {
    display: flex;
    align-items: center;
}

.row h2 {
    font-weight: 600;
}

.row p {
    font-size: 12px;
}

.rows {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    filter: drop-shadow(0px 0px 1px #000000);
    margin-right: 1rem;
}

.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.paper-container {
    display: flex;
    justify-items: space-between;
    align-items: center;
}

.tab {
    border-bottom: 1px solid #e6e6e6;
}