.login-screen {
    /* border: 1px solid red; */
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
}

.login-container {
    border: 1px solid #fcf9fa;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    padding: 2rem 3rem;
    min-height: 487px;
    min-width: 522px;
}

.login-container h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
}

.login-container p {
    font-size: 14px;
    color: #505050;
    line-height: 18px;
    margin-top: 1rem;
}

.form {
    margin-top: 1rem;
}

.form-control label {
    display: block;
}

.form-control input {
    display: block;
    border: 1px solid #ccc;
    width: 100%;
    padding: 0.6rem 0.8rem;
    border-radius: 4px;
    margin-top: 0.5rem;
}

.form-control {
    margin-bottom: 1rem;
}

.form button {
    background-color: #e6072c;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    padding: 0.6rem 0.8rem;
    margin-top: 2rem;
}