.container {
    margin: 2rem 3rem;
}

.paper {
    display: flex;
    justify-items: space-between;
    align-items: center;
}

.tab-panel-body {
    padding: 2rem 2.8rem;
}

.table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

.table th {
    opacity: 0.5;
}

.table td {
    font-weight: 700;
    text-transform: capitalize;
}

.table td:not(td:first-of-type),
.table th:not(th:first-of-type) {
    padding-left: 8rem;
}

.table-row td {
    vertical-align: text-top;
    padding-bottom: 1rem;
}

.table-row th {
    padding-top: 2rem;
    white-space: nowrap;
}

.benefits-container {
    line-height: 28px;
}

.show-more-btn {
    font-weight: bold;
    text-decoration: underline;
}