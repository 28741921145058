.container {
  margin: 2rem 3rem;
}

.paper {
  display: flex;
  justify-items: space-between;
  align-items: center;
  border: 1px solid red;
}

.tab_panel_body {
  padding: 2rem 2.8rem;
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.table th {
  opacity: 0.5;
}

.table-row th {
  padding-top: 2rem;
  white-space: nowrap;
}

.table-row td {
  vertical-align: text-top;
}

.table td {
  font-weight: 700;
  text-transform: capitalize;
}

.table td:not(td:first-of-type),
.table th:not(th:first-of-type) {
  padding-left: 8rem;
}

.table_row td {
  vertical-align: text-top;
  /* white-space: nowrap; */
}

.table_row th {
  white-space: nowrap;
  padding-top: 2rem;
}

.benefits-container {
  line-height: 28px;
}

.show-more-btn {
  font-weight: bold;
  text-decoration: underline;
}

.underline {
  border-bottom: 3px solid #251a6b;
  margin-bottom: 2rem;
  width: 50%;
}
.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #251a6b;
}
