.form {
  margin-top: 2rem;
}

.form-control label {
  display: block;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
}

.name-input {
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.value-input1 {
  display: inline-block;
  border: 1px solid #ccc;
  width: 90%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  margin-left: 5%;
}

.value-input2 {
  display: inline-block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  margin-left: 5%;
  margin-right: 0;
}

.form-control select {
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}
.form-control {
  margin-bottom: 0.5rem;
}

.form-control-row {
  margin-bottom: 0.5rem;
  float: left;
  width: 60%;
}
.form-control-row-2 {
  margin-bottom: 0.5rem;
  float: right;
  width: 38%;
}
.form-control-row-1 {
  /* margin-bottom: -15rem; */
  /* margin-left: -12rem; */
  /* float: left; */
  /* width: 60%; */
}
.addCircle {
  color: #32a887;
}
.btn1 {
  display: block;
}
.btn2 {
  margin-top: -0.6rem;
  display: block;
}
.btn3 {
  margin-top: 6rem;

  display: block;
  color: #525252;
}
.input-up-down {
  margin-left: 5.5rem;
  margin-top: -2.4rem;
}
.btn {
  background-color: #e6072c;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem 0.8rem;
  margin-top: 1rem;
}
