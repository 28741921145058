.form {
  margin-top: 2rem;
}

.form-control label {
  display: block;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
}
.error {
  font-size: 13px;
  color: #e6072c;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
}

.name-input {
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.form-control select {
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.form-control input {
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}
.form-control {
  margin-bottom: 1rem;
}

.form button {
  background-color: #e6072c;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem 0.8rem;
  margin-top: 1rem;
}
