.details-title {
  font-size: 18px;
  font-weight: 900;
  color: rgba(230, 7, 44, 1);
}
.details-control {
  margin-bottom: 1.5rem;
}
.underline {
  border-bottom: 3px solid rgba(230, 7, 44, 1);
  margin-bottom: 1rem;
  width: 30%;
}
.key {
  font-size: 14px;
  font-weight: 700;
  color: rgba(153, 153, 153, 1);
  margin: 0 !important;
}
.value {
  font-size: 14px;
  font-weight: 700;
  color: rgba(106, 106, 106, 1);
  margin-top: 0.1rem;
}
